//
// Select 2
// --------------------------------------------------

// Mixins
// -------------------------

@mixin bootstrap-input-defaults {
	background-color: $input-bg;
	border: 1px solid $input-border;
	border-radius: $input-border-radius;
	color: $input-color;
	font-family: $font-family-base;
	font-size: $input-font-size;
}

@mixin validation-state-focus($color) {
	$shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 6px lighten($color, 20%);

	.select2-dropdown,
	.select2-selection {
		border-color: $color;
	}

	.select2-container--focus .select2-selection,
	.select2-container--open .select2-selection {
		@include box-shadow($shadow);
		border-color: darken($color, 10%);
	}

	&.select2-drop-active {
		border-color: darken($color, 10%);

		&.select2-drop.select2-drop-above {
			border-top-color: darken($color, 10%);
		}
	}
}

// dropdown arrow when dropdown is open
@mixin dropdown-arrow {
	.select2-selection--single {
		/**
		 * Make the dropdown arrow point up while the dropdown is visible.
		 */

		.select2-selection__arrow b {
			border-color: transparent transparent $gray-dark transparent;
			border-width: 0 $caret-width-large $caret-width-large $caret-width-large;
		}
	}
}

// Base Styles
// -------------------------

.select2{
	width: 100%!important;
}

.select2-container {
	box-sizing: border-box;
	display: inline-block;
	margin: 0;
	position: relative;
	vertical-align: middle;

	.select2-selection--single {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		height: 50px;
		user-select: none;
		-webkit-user-select: none;

		.select2-selection__rendered {
			display: block;
			padding-left: 20px;
			padding-right: 20px;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.select2-selection__clear {
			position: relative;
		}
	}

	&[dir="rtl"] {
		.select2-selection--single {
			.select2-selection__rendered {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}

	.select2-selection--multiple {
		box-sizing: border-box;
		cursor: pointer;
		display: block;
		min-height: 32px;
		user-select: none;
		-webkit-user-select: none;

		.select2-selection__rendered {
			display: inline-block;
			overflow: hidden;
			padding-left: 20px;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
	}

	.select2-search--inline {
		float: left;

		.select2-search__field {
			box-sizing: border-box;
			border: none;
			margin-top: 5px;
			padding: 0;

			&::-webkit-search-cancel-button {
				-webkit-appearance: none;
			}
		}
	}

}

.select2-dropdown {
	background-color: $body-bg;
	border: 1px solid $gray-dark;
	border-radius: 4px;
	box-sizing: border-box;
	display: block;
	position: absolute;
	left: -100000px;
	width: 100%;
	z-index: 12;
}

.select2-results {
	display: block;
}

.select2-results__options {
	list-style: none;
	margin: 0;
	padding: 0;
}

.select2-results__option {
	padding: 10px 15px;
	font-size: 14px;
	transition: .3s;
	user-select: none;
	-webkit-user-select: none;

	&[aria-selected] {
		cursor: pointer;
	}
}

.select2-container--open .select2-dropdown {
	left: 0;
}

.select2-container--open .select2-dropdown--above {
	border-bottom: none;
	border-bottom-left-radius: 0;
	border-bottom-right-radius: 0;
}

.select2-container--open .select2-dropdown--below {
	border-top: none;
	border-top-left-radius: 0;
	border-top-right-radius: 0;
}

.select2-search--dropdown {
	display: block;
	padding: 4px;

	.select2-search__field {
		width: 100%;
		padding: 4px;
		box-sizing: border-box;

		&::-webkit-search-cancel-button {
			-webkit-appearance: none;
		}
	}

	&.select2-search--hide {
		display: none;
	}
}

.select2-close-mask {
	border: 0;
	margin: 0;
	padding: 0;
	display: block;
	position: fixed;
	left: 0;
	top: 0;
	min-height: 100%;
	min-width: 100%;
	height: auto;
	width: auto;
	opacity: 0;
	z-index: 99;
	background-color: $white;
	filter: alpha(opacity=0);
}

.select2-hidden-accessible {
	border: 0 !important;
	clip: rect(0 0 0 0) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
}

.select2 + .form-validation{
	z-index: 14;
}

// Select 2 Bootstrap Theme
// -------------------------

.select2-container--bootstrap {
	display: block;

	.select2-selection {
		@include bootstrap-input-defaults;
		outline: 0;
	}

	// Search field in the Select2 dropdown.
	.select2-search--dropdown {
		.select2-search__field {
			@include bootstrap-input-defaults;
		}
	}

	// No outline for all search fields - in the dropdown
	// and inline in multi Select2s.
	.select2-search__field {
		outline: 0;
		font-size: $input-font-size;

		&::-webkit-input-placeholder {
			color: $input-color-placeholder;
		}

		&:-moz-placeholder {
			color: $input-color-placeholder;
		}

		&::-moz-placeholder {
			color: $input-color-placeholder;
			opacity: 1;
		}

		&:-ms-input-placeholder {
			color: $input-color-placeholder;
		}
	}

	// Adjust Select2's choices hover and selected styles to match
	// Bootstrap 3's default dropdown styles.
	.select2-results__option {

		&[role=group] {
			padding: 0;
		}

		// Disabled results.
		&[aria-disabled=true] {
			color: $dropdown-link-disabled-color;
			cursor: $cursor-disabled;
		}

		// Hover state.
		&[aria-selected=true] {
			background-color: $dropdown-link-hover-bg;
			color: $dropdown-link-hover-color;
		}

		// Selected state.
		&--highlighted[aria-selected] {
			background-color: $dropdown-link-active-bg;
			color: $dropdown-link-active-color;
		}

		.select2-results__option {
			padding: 10px 20px;

			.select2-results__group {
				padding-left: 0;
			}

			.select2-results__option {
				margin-left: -$padding-base-horizontal;
				padding-left: $padding-base-horizontal*2;

				.select2-results__option {
					margin-left: -$padding-base-horizontal*2;
					padding-left: $padding-base-horizontal*3;

					.select2-results__option {
						margin-left: -$padding-base-horizontal*3;
						padding-left: $padding-base-horizontal*4;

						.select2-results__option {
							margin-left: -$padding-base-horizontal*4;
							padding-left: $padding-base-horizontal*5;

							.select2-results__option {
								margin-left: -$padding-base-horizontal*5;
								padding-left: $padding-base-horizontal*6;
							}
						}
					}
				}
			}
		}
	}

	.select2-results__group {
		color: $dropdown-header-color;
		display: block;
		padding: $padding-base-vertical $padding-base-horizontal;
		font-weight: 700;
		font-size: 16px;
		line-height: (30/16);
		white-space: nowrap;
	}

	&.select2-container--focus,
	&.select2-container--open {
		.select2-selection {
			@include transition(border-color ease-in-out .15s, box-shadow ease-in-out .15s);
			border-color: $input-border-focus;
		}
	}

	&.select2-container--open {

		// Make the dropdown arrow point up while the dropdown is visible.
		.select2-selection .select2-selection__arrow b {
			border-color: transparent transparent $primary transparent;
			border-width: 0 5px 5px 5px;
		}

		// Handle border radii of the container when the dropdown is showing.
		&.select2-container--below {
			.select2-selection {
				@include border-bottom-radius(0);
			}
		}

		&.select2-container--above {
			.select2-selection {
				@include border-top-radius(0);
			}
		}
	}

	// Clear the selection.
	.select2-selection__clear {
		color: $gray-dark;
		cursor: pointer;
		float: right;
		font-weight: 400;
		margin-right: 10px;

		&:hover {
			color: $primary;
		}
	}

	// Address disabled Select2 styles.
	&.select2-container--disabled {

		.select2-selection {
			border-color: $input-border;
			@include box-shadow(none);
		}

		.select2-selection,
		.select2-search__field {
			cursor: $cursor-disabled;
		}

		.select2-selection,
		.select2-selection--multiple .select2-selection__choice {
			background-color: $input-bg-disabled;
		}

		.select2-selection__clear,
		.select2-selection--multiple .select2-selection__choice__remove {
			display: none;
		}
	}

	// Dropdown border color and box-shadow.
	.select2-dropdown {
		border-color: $input-border-focus;
		overflow-x: hidden;
	}

	// Limit the dropdown height.
	.select2-results > .select2-results__options {
		max-height: 200px;
		overflow-y: auto;
	}

	// Single select
	.select2-selection--single {
		text-align: left;
		height: $input-height-base;
		line-height:  $input-height-base;
		padding: 0 15px;

		// Adjust the single Select2's dropdown arrow button appearance.
		.select2-selection__arrow {
			position: absolute;
			bottom: 0;
			right: $padding-base-horizontal;
			top: 0;

			b {
				&:before{
					position: absolute;
					top: 50%;
					font-weight: 400;
					margin-top: -10px;
					right: 0;
					height: 20px;
					line-height: 20px;
					content: '\e313';
					font-family: 'Material Icons';
					font-size: 20px;
				}
			}
		}

		.select2-selection__rendered {
			color: $input-color;
			padding: 0;
		}

		.select2-selection__placeholder {
			color: $input-color-placeholder;
		}
	}

	// Mutiple
	.select2-selection--multiple {
		min-height: $input-height-base;

		.select2-selection__rendered {
			box-sizing: border-box;
			display: block;
			line-height: $btn-lh-size;
			list-style: none;
			margin: 0;
			overflow: hidden;
			padding: 0;
			width: 100%;
			text-overflow: ellipsis;
			white-space: nowrap;
		}

		.select2-selection__placeholder {
			color: $input-color-placeholder;
			float: left;
			margin-top: 5px;
		}

		// Make Multi Select2's choices match Bootstrap 3's default button styles.
		.select2-selection__choice {
			color: $input-color;
			background: $btn-default-bg;
			border: 1px solid $btn-default-border;
			border-radius: $border-radius-small;
			cursor: default;
			float: left;
			margin: 0;
			padding: 0 $padding-base-vertical;
		}

		// Minus 2px borders.
		.select2-search--inline {
			.select2-search__field {
				background: transparent;
				padding: 0 $padding-base-horizontal;
				height: $input-height-base - 2;
				line-height: $btn-lh-size;
				margin-top: 0;
				min-width: 5em;
			}
		}

		.select2-selection__choice__remove {
			color: $gray-dark;
			cursor: pointer;
			display: inline-block;
			font-weight: bold;
			margin-right: $padding-base-vertical / 2;

			&:hover {
				color: $primary;
			}
		}

		// Clear the selection.
		.select2-selection__clear {
			margin-top: $padding-base-vertical;
		}
	}

	// Address Bootstrap control sizing classes
	&.input-sm,
	&.input-lg {
		border-radius: 0;
		font-size: 12px;
		height: auto;
		line-height: 1;
		padding: 0;
	}

	&.input-sm,
	.input-group-sm &,
	.form-wrap-sm & {
		.select2-selection--single {
			border-radius: $border-radius-small;
			font-size: $font-size-small;
			height: $input-height-small;
			line-height: $line-height-small;
			padding: $padding-small-vertical $padding-small-horizontal + $caret-width-base*3 $padding-small-vertical $padding-small-horizontal;

			.select2-selection__arrow b {
				margin-left: -$padding-small-vertical;
			}
		}

		.select2-selection--multiple {
			min-height: $input-height-small;

			.select2-selection__choice {
				font-size: $font-size-small;
				line-height: $line-height-small;
				margin: ($padding-small-vertical - 1) 0 0 $padding-small-horizontal/2;
				padding: 0 $padding-small-vertical;
			}

			.select2-search--inline .select2-search__field {
				padding: 0 $padding-small-horizontal;
				font-size: $font-size-small;
				height: $input-height-small - 2;
				line-height: $line-height-small;
			}

			.select2-selection__clear {
				margin-top: $padding-small-vertical;
			}
		}
	}

	&.input-lg,
	.input-group-lg &,
	.form-wrap-lg & {
		.select2-selection--single {
			border-radius: $border-radius-large;
			font-size: $font-size-large;
			height: $input-height-large;
			line-height: $line-height-large;
			padding: $padding-large-vertical $padding-large-horizontal + $caret-width-large*3 $padding-large-vertical $padding-large-horizontal;

			.select2-selection__arrow {
				width: $caret-width-large;

				b {
					border-width: $caret-width-large $caret-width-large 0 $caret-width-large;
					margin-left: -$caret-width-large;
					margin-left: -$padding-large-vertical;
					margin-top: -$caret-width-large/2;
				}
			}
		}

		.select2-selection--multiple {
			min-height: $input-height-large;

			.select2-selection__choice {
				font-size: $font-size-large;
				line-height: $line-height-large;
				border-radius: 4px;
				margin: ($padding-large-vertical - 1) 0 0 $padding-large-horizontal/2;
				padding: 0 $padding-large-vertical;
			}

			.select2-search--inline .select2-search__field {
				padding: 0 $padding-large-horizontal;
				font-size: $font-size-large;
				height: $input-height-large - 2;
				line-height: $line-height-large;
			}

			.select2-selection__clear {
				margin-top: $padding-large-vertical;
			}
		}
	}

	&.input-lg.select2-container--open {
		@include dropdown-arrow;
	}

	.input-group-lg & {
		&.select2-container--open {
			@include dropdown-arrow;
		}
	}

	// RTL SUPPORT
	&[dir="rtl"] {

		// Single Select2
		.select2-selection--single {
			padding-left: $padding-base-horizontal + $caret-width-base*3;
			padding-right: $padding-base-horizontal;
			.select2-selection__rendered {
				padding-right: 0;
				padding-left: 0;
				text-align: right; /* 1 */
			}

			.select2-selection__clear {
				float: left;
			}

			.select2-selection__arrow {
				left: $padding-base-horizontal;
				right: auto;

				b {
					margin-left: 0;
				}
			}
		}

		// Multiple Select2
		.select2-selection--multiple {
			.select2-selection__choice,
			.select2-selection__placeholder {
				float: right;
			}

			.select2-selection__choice {
				margin-left: 0;
				margin-right: $padding-base-horizontal/2;
			}

			.select2-selection__choice__remove {
				margin-left: 2px;
				margin-right: auto;
			}
		}
	}
}

// Address Bootstrap's validation states
.has-warning {
	@include validation-state-focus($state-warning-text);
}

.has-error {
	@include validation-state-focus($state-danger-text);
}

.has-success {
	@include validation-state-focus($state-success-text);
}

// Select2 widgets in Bootstrap Input Groups
.input-group .select2-container--bootstrap {
	display: table;
	table-layout: fixed;
	position: relative;
	z-index: 2;
	float: left;
	width: 100%;
	margin-bottom: 0;
}

.input-group.select2-bootstrap-prepend .select2-container--bootstrap {
	.select2-selection {
		@include border-left-radius(0);
	}
}

.input-group.select2-bootstrap-append .select2-container--bootstrap {
	.select2-selection {
		@include border-right-radius(0);
	}
}

// Adjust alignment of Bootstrap buttons in Bootstrap Input Groups to address
.select2-bootstrap-append,
.select2-bootstrap-prepend {
	.select2-container--bootstrap,
	.input-group-btn,
	.input-group-btn .btn {
		vertical-align: top;
	}
}

// Temporary fix for https://github.com/select2/select2-bootstrap-theme/issues/9
.form-input.select2-hidden-accessible {
	position: absolute !important;
	width: 1px !important;
}

// Display override for inline forms
.form-inline .select2-container--bootstrap {
	display: inline-block;
}

.select2-container--bootstrap .select2-selection,
.select2-container--bootstrap .select2-selection--single .select2-selection__rendered {
	color: $input-color-placeholder;
}